<template>
  <div>
    <div class="header">
      <v-img
        lazy-src="@/assets/images/contactUsbanner.png"
        max-height="346"
        max-width="100%"
        eager
        src="@/assets/images/contactUsbanner.png"
      >
        <template>
          <div class="header-text">联系我们</div>
        </template>
      </v-img>
    </div>
    <div class="content">
      <div class="form">
        <v-form ref="form" lazy-validation>
           <v-text-field
              v-model="form.coName"
              :rules="[rules.required,rules.isScript,rules.isUserNmae]"
              label="公司名称*"
              maxlength="50"
              filled
              required
            >
              <template v-slot:prepend>
                <div style="width:80px">公司名称*</div>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.email"
              :rules="[rules.required,  rules.email]"
              label="工作邮箱*"
              maxlength="30"
              filled
              required
            >
              <template v-slot:prepend>
                <div style="width:80px">工作邮箱*</div>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.phonenumber"
              :rules="[rules.required, rules.phone]"
              label="联系电话*"
              maxlength="11"
              filled
              required
            >
              <template v-slot:prepend>
                <div style="width:80px">联系电话*</div>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.name"
              :rules="[rules.required,rules.isScript,rules.isUserNmae]"
              label="您的姓名*"
              maxlength="30"
              filled
              required
            >
              <template v-slot:prepend>
                <div style="width:80px">您的姓名*</div>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.position"
              :rules="[rules.isScript,rules.isUserNmae]"
              label="您的职位"
              filled
              maxlength="30"
            >
              <template v-slot:prepend>
                <div style="width:80px">您的职位</div>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.industry"
              :rules="[rules.isScript,rules.isUserNmae]"
              label="所属行业"
              filled
              maxlength="30"
            >
              <template v-slot:prepend>
                <div style="width:80px">所属行业</div>
              </template>
            </v-text-field>
            <v-textarea
              filled
              name="input-7-4"
              label="留言*"
              v-model="form.need"
              :rules="[rules.required,rules.isScript,rules.isText]"
              maxlength="300"
              counter="300"
            >
              <template v-slot:prepend>
                <div style="width:80px">您的留言*</div>
              </template>
            </v-textarea>
            <v-row align="center" justify="space-around">
              <v-btn
                class="mx-2 submitBtn"
                elevation="6"
                color="#716FDB"
                :loading="btnLoading"
                @click="submitForm()"
              >
                提交信息
              </v-btn>
            </v-row>
        </v-form>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
    >
      {{snackbar.text}}
    </v-snackbar>
  </div>
</template>
<script>
import { addUser } from "@/api/index";
export default {
  name: "contactUs",
  components: {},
  beforeCreate() {},
  created() {},
  beforeDestroy() {},
  filters: {},
  data() {
    return {
      form:{},
      snackbar:{
        show:false,
        timeout:2000,
        text:''
      },
      btnLoading:false,
      rules:{
        required: value => !!value || ' ',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '请输入合法邮箱'
          },
        phone:value => {
          const pattern = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
          return pattern.test(value) || '请输入正确的手机号'
        },
        isScript:value => {
          const pattern = /(?<=<script\b[^>]*>)[\s\S]*(?=<\/script>)/g
          return !pattern.test(value) || '请输入合法内容'
        },
        isText:value=>{
          const pattern = /[`~!@#$%^&*()_\-+=<>:"{}|\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》：“”【】、；‘']/im; 
          return !pattern.test(value) || '不可包含特殊字符'
        },
        isUserNmae:value => {
          const pattern = /^[\u4e00-\u9fa5a-zA-Z]+$/;
          return pattern.test(value) || '请输入中文或英文字符，不包含特殊字符'
        }
      }
    };
  },
  methods: {
    submitForm(){
      this.btnLoading = true;
      if(this.$refs.form.validate()){
        addUser(this.form).then(res=>{
          if(res.code == 200){
            this.btnLoading = false;
            this.snackbar.text = "提交成功";
            this.snackbar.show = true;
          }
        })
      }else{
         this.btnLoading = false;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  .header-text {
    text-align: center;
    color: #fff;
    font-size: 0.5rem;
    font-weight: 500;
    line-height: 3.46rem;
    -webkit-background-clip: text;
  }
}
.content{
  padding-top: 0.7rem;
  background: #FFFFFF;
  box-shadow: inset 0px -0.03rem 0.06rem 0.01rem rgba(0,0,0,0.16);
  .form{
    width: 40%;
    margin: 0 auto;
    padding-bottom: 1.86rem;
    .submitBtn{
      height: 0.36rem;
      font-size: 0.14rem;
      padding: 0 0.3rem !important;
    }
  }
}
::v-deep .v-textarea textarea{
  line-height: 0.25rem;
}
::v-deep .v-snack__content{
  font-size: 0.16rem;
  line-height:0.2rem;
  padding: 0.14rem 0.16rem;
}
</style>
